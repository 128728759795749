@use '@standby/dike_ui/design-token/color.module.scss' as color;
.modalContent {
  width: 472px;
  .companyInfo {
    padding: 8px 12px;
    width: 100%;
    border: 1px solid color.$grayScale300;
    border-radius: 4px;
    background-color: color.$grayScale000;
    text-align: left;
    display: flex;
    flex-direction: column;
    .companyTitle {
      color: color.$grayScale800;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .companyAddress {
      color: color.$grayScale500;
    }
  }
  .btnArea {
    margin-top: 24px;
    text-align: center;
    padding-bottom: 20px;
  }
}

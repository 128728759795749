@import '@standby/common-ui/color.scss';

.column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;

  .addBtn {
    width: fit-content;
    height: 20px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 20px;

  .name {
    margin-top: 10px;

    color: $neutral800;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.32px;
  }

  .selectRow {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .minusBtn {
    width: 25px;
    height: 25px;

    margin-top: 8px;
  }
}

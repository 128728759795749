@use '@standby/dike_ui/design-token/color.module.scss' as color;

.infoInputWrap {
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  margin-top: 96px;
  align-items: flex-start;
  .infoInputs {
    width: 740px;
    display: flex;
    flex-flow: column;
    gap: 16px;
    .infoInput {
      width: 100%;
      padding: 24px 28px;
      border: 1px solid color.$grayScale200;
      border-radius: 8px;
      .titleWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          display: flex;
          gap: 8px;
          align-items: center;
          .icon {
            width: 24px;
            height: 24px;
            background: color.$primary400;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .titleText {
            color: color.$grayScale800;
          }
        }

        .link {
          color: color.$grayScale700;
        }
      }
      .radioList {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        label {
          padding: 12px 16px;
          border: 1px solid color.$grayScale300;
          border-radius: 8px;
          width: 220px;
          display: flex;
          flex-flow: column;
          cursor: pointer;
          &.active {
            border-color: color.$primary400;
            .name {
              color: color.$primary500;
            }
          }
          input {
            display: none;
          }
          .name {
            color: color.$grayScale800;
          }
          .price {
            border-top: 1px solid color.$grayScale200;
            padding-top: 8px;
            margin-top: 12px;
            color: color.$grayScale700;
          }
        }
      }
      .tip {
        margin-top: 12px;
        color: color.$grayScale800;
        height: 36px;
        display: flex;
        align-items: center;
        background-color: color.$primary50;
        padding-left: 40px;
        border-radius: 4px;
        background-image: url('/images/lightTip_20.svg');
        background-position: left 12px center;
        background-repeat: no-repeat;
      }
      .inputs {
        display: flex;
        flex-flow: column;
        margin-top: 12px;
        gap: 16px;
        .inquiryTextarea {
          height: 60px;
        }
      }
    }
    .termWrap {
      .termsList {
        padding: 16px;
        background-color: color.$grayScale100;
        margin-top: 5px;
        display: flex;
        flex-flow: column;
        gap: 7px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .textBtn {
            color: color.$grayScale500;
          }
        }
      }
    }
  }
}
.agreeContent {
  padding: 16px 20px 20px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: color.$grayScale900;
}

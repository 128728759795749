.errorPage {
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css');

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  overflow: hidden;

  background-color: #07174e;

  .guide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    background: #fff;

    overflow: hidden;

    width: 520px;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 16px 48px;

      box-shadow: 4px 4px 10px 0px rgba(50, 56, 74, 0.25);

      img {
        width: 144px;
        height: 36px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;

      width: 100%;
      padding: 48px;

      img {
        width: 240px;
        height: 240px;
      }

      .errorContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8px;

        width: 100%;

        .title {
          color: #121212;

          /* Title-24M */
          font-family: Pretendard;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px; /* 133.333% */
        }

        .description {
          color: #545557;
          text-align: center;

          /* Body-16M */
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        width: 100%;

        .button {
          flex: 1 0 0;

          display: flex;
          justify-content: center;
          align-items: center;

          padding: 12px;

          border-radius: 4px;

          text-align: center;

          /* Body-16R */
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */

          &Back {
            @extend .button;

            background-color: #fff;
            color: #4c49e5;
            border: 1px solid #4c49e5;
          }

          &Home {
            @extend .button;

            background-color: #4c49e5;
            color: #fff;
          }
        }
      }
    }
  }
}

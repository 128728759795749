@use '@standby/dike_ui/design-token/color.module.scss' as color;

.companyNameSearch {
  width: 512px;
  padding-bottom: 20px;
  .form {
    .row {
      display: flex;
      margin-top: 8px;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      .title {
        width: 104px;
        flex-shrink: 0;
        color: color.$grayScale900;
      }
      .content {
        width: 100%;
        .contentRow {
          display: flex;
          justify-content: space-between;
          .select {
            width: 202px;
          }
          .submitBtn {
            margin-left: 4px;
            width: 72px;
          }
        }
        .explain {
          display: inline-block;
          margin-top: 2px;
          color: color.$grayScale700;
        }
      }
    }
  }
  .companyListBox {
    margin-top: 24px;
    width: 100%;
    .companyListEmpty {
      border-top: 1px solid color.$grayScale200;
      padding-top: 24px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-flow: column;
      .emptyInfo {
        width: 100%;
        .empty {
          display: inline-block;
          margin-top: 8px;
          color: color.$grayScale700;
          .bold {
            color: color.$grayScale900;
          }
        }
        .emptyExplainBox {
          display: flex;
          justify-content: space-between;
          padding: 12px 20px;
          padding-left: 28px;
          margin-top: 12px;
          width: 100%;
          background-color: color.$grayScale100;
          border-radius: 8px;
          border: 1px solid color.$grayScale200;
          text-align: left;
          margin-left: 0 !important;
          .emptyExplain {
            color: color.$grayScale800;
            .sub {
              color: color.$grayScale500;
              margin-top: 4px;
              display: inline-block;
            }
          }
        }
      }
    }
    .companyList {
      padding: 12px;
      border-radius: 8px;
      background-color: color.$grayScale100;

      .scrollArea {
        height: 336px !important;
        position: 'none';
      }

      .companyInfoList {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
  .nextBtnArea {
    margin-top: 24px;
    width: 100%;
    text-align: center;
    .nextBtn {
      width: 248px;
    }
  }
}

@media screen and (max-height: 600px) {
  .companyNameSearch {
    .companyListBox {
      .companyList {
        .scrollArea {
          height: 150px !important;
          position: 'none';
        }
      }
    }
  }
}

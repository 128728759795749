@use '@standby/dike_ui/design-token/color.module.scss' as color;
@import '@standby/common-ui/color.scss';

.afterApplicationSubmission {
  .step {
    width: 100%;
    height: 150px;
    background-image: url('/images/flexline_step_background.svg');
    margin-top: 24px;
    display: flex;
    flex-flow: column;
    padding-left: 40px;
    .badge {
      flex-grow: 0;
      align-self: flex-start;
      color: color.$primary300;
      padding: 1px 4px;
      border-radius: 2px;
      background-color: color.$primary50;
      margin-top: 28px;
    }
    .title {
      margin-top: 16px;
      color: color.$grayScale000;
    }
    .content {
      margin-top: 4px;
      color: color.$primary50;
    }
  }
  .mainContent {
    .row {
      margin-top: 48px;
      .title {
        color: color.$primary900;
      }
      .sub {
        display: block;
        margin-top: 4px;
        color: color.$grayScale800;
      }
      &.rowSpaceBetween {
        display: flex;
        justify-content: space-between;
        .listContent {
          > .list {
            width: 512px;
            margin-top: 12px;
            li {
              color: color.$grayScale700;
              padding: 8px 6px;
              border-bottom: 1px dashed color.$grayScale300;
              em {
                color: color.$grayScale800;
              }
              &:first-child {
                border-top: 1px dashed color.$grayScale300;
              }
            }
          }
        }
        .copy {
          width: 512px;
          height: 148px;
          border-radius: 12px;
          border: 1px solid color.$grayScale200;
          background-color: color.$grayScale100;
          text-align: center;
          background-image: url('/images/linkBoxIcon.svg');
          background-repeat: no-repeat;
          background-position: top 24px center;
          &.telephone {
            background-image: url('/images/telephoneBoxIcon.svg');
          }
          .title {
            display: block;
            margin-top: 52px;
            color: color.$grayScale700;
            em {
              color: color.$primary800;
            }
          }
          .copyArea {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            margin-top: 16px;
            .copyText {
              display: inline-block;
              width: 385px;
              height: 40px;
              padding: 10px;
              border-radius: 4px;
              border: 1px solid color.$grayScale300;
              background: color.$grayScale000;
              text-align: left;
            }
          }
        }
      }
      .advertisementCards {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        button {
          background-color: transparent;
        }
      }
    }
  }
}

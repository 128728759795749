@import '@standby/common-ui/color.scss';

.checkboxList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  b {
    font-weight: 500;
  }
}

.addressRow {
  width: 460px;

  &:first-child {
    border-top: 1px solid $neutral300;
  }

  .header {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    padding: 8px 10px;
    height: auto;

    background: $neutral200;

    border-bottom: 1px solid $neutral300;

    .category {
      flex-shrink: 0;

      color: $neutral800;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
    }

    .address {
      flex-grow: 1;

      color: $neutral800;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      letter-spacing: -0.28px;
    }
  }

  .content {
    width: 100%;
    padding: 8px 12px;

    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    gap: 20px;

    border-bottom: 1px solid $neutral300;
  }
}

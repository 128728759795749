@use '@standby/dike_ui/design-token/color.module.scss' as color;

@keyframes squareMoving {
  from {
    transform: translateY(13px);
  }
  to {
  }
}

.joinBackground {
  background: linear-gradient(
    180deg,
    #07174e 0%,
    #0a3075 12.81%,
    #1b61ad 29.61%,
    #5099dc 46.56%,
    #6ac5f8 62.37%,
    #a0e2ff 74.85%,
    #f0fafe 90.77%,
    #fff 100%
  );
  position: absolute;
  height: 794px;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  min-width: 723px;
  .firecracker {
    position: absolute;
    min-width: 1600px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    img {
      transition:
        opacity 2s ease-out,
        transform 1s;
      opacity: 0.3;
      position: absolute;
      transform: scale(0);
      &:nth-child(1) {
        top: 130px;
        left: 0;
      }
      &:nth-child(2) {
        top: 220px;
        left: 370px;
      }
      &:nth-child(3) {
        top: 50px;
        left: 450px;
      }
      &:nth-child(4) {
        top: 90px;
        left: 470px;
      }
      &:nth-child(5) {
        top: 206px;
        right: 492px;
      }
      &:nth-child(6) {
        top: 20px;
        right: 430px;
      }
      &:nth-child(7) {
        top: 320px;
        right: 330px;
      }
      &:nth-child(8) {
        top: 80px;
        right: 0px;
      }
    }
  }
  .square {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 330px;
    div {
      position: absolute;
      top: 200px;
      left: 0px;
      opacity: 0;
      transform-origin: center;
      transition:
        top 0.7s ease-in-out,
        left 0.7s ease-in-out,
        opacity 1s;
      &:nth-child(1) {
        width: 9px;
        height: 9px;
        background-color: #fff;
        transform: rotate(100deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
      &:nth-child(2) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(150deg);
        animation: squareMoving 1.3s infinite;
        animation-direction: alternate;
      }
      &:nth-child(3) {
        width: 14px;
        height: 14px;
        background-color: #00b4e3;
        transform: rotate(80deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(4) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(200deg);
        animation: squareMoving 1s infinite;
        animation-direction: alternate;
      }
      &:nth-child(5) {
        width: 6px;
        height: 6px;
        background-color: #fff0a3;
        transform: rotate(150deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(6) {
        width: 6px;
        height: 6px;
        background-color: #a3f9ff;
        transform: rotate(-120deg);
        animation: squareMoving 1.6s infinite;
        animation-direction: alternate;
      }
      &:nth-child(7) {
        width: 9px;
        height: 9px;
        background-color: #fff;
        transform: rotate(130deg);
        animation: squareMoving 2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(8) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(100deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
      &:nth-child(9) {
        width: 9px;
        height: 9px;
        background-color: #00b4e3;
        transform: rotate(120deg);
        animation: squareMoving 0.9s infinite;
        animation-direction: alternate;
      }
      &:nth-child(10) {
        width: 14px;
        height: 14px;
        background-color: #fff0a3;
        transform: rotate(80deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(11) {
        width: 6px;
        height: 6px;
        background-color: #a3f9ff;
        transform: rotate(180deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
    }
  }
  &.action {
    .firecracker {
      img {
        opacity: 1;
        transform: scale(1);
        &:nth-child(2) {
          transition-delay: 0.3s;
        }
        &:nth-child(5) {
          transition-delay: 0.6s;
        }
        &:nth-child(7) {
          transition-delay: 0.9s;
        }
        &:nth-child(3) {
          transition-delay: 1.2s;
        }
        &:nth-child(4) {
          transition-delay: 1.6s;
        }
        &:nth-child(1) {
          transition-delay: 1.5s;
        }
        &:nth-child(8) {
          transition-delay: 1.2s;
        }
      }
    }
    .square {
      div {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition-delay: 2.5s;
        &:nth-child(1) {
          top: -170px;
          left: -500px;
        }
        &:nth-child(2) {
          top: -280px;
          left: -450px;
        }
        &:nth-child(3) {
          top: -200px;
          left: -400px;
        }
        &:nth-child(4) {
          top: -130px;
          left: -280px;
        }
        &:nth-child(5) {
          top: -310px;
          left: -170px;
        }
        &:nth-child(6) {
          top: -290px;
          left: 160px;
        }
        &:nth-child(7) {
          top: -240px;
          left: 190px;
        }
        &:nth-child(8) {
          top: -110px;
          left: 210px;
        }
        &:nth-child(9) {
          top: -50px;
          left: 290px;
        }
        &:nth-child(10) {
          top: -130px;
          left: 360px;
        }
        &:nth-child(11) {
          top: -250px;
          left: 400px;
        }
      }
    }
  }
}
.companyRegistraion {
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 60px;
  width: 723px;
  .form {
    margin: 0 auto;
    margin-top: 48px;
    padding: 32px 48px;
    width: 520px;
    border-radius: 20px;
    background-color: color.$grayScale000;
    box-shadow: 4px 4px 10px 0px rgba(50, 56, 74, 0.25);
    .row {
      margin-top: 32px;
      &:first-child {
        margin-top: 0;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
      }
      .companyInfo {
        padding: 8px 12px;
        width: 100%;
        border: 1px solid color.$grayScale300;
        border-radius: 4px;
        background-color: color.$grayScale000;
        text-align: left;
        display: flex;
        flex-direction: column;
        .companyTitle {
          color: color.$grayScale800;
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .companyAddress {
          color: color.$grayScale500;
        }
      }
    }
    .btnArea {
      margin-top: 40px;
      text-align: center;
      .squareBtn {
        width: 100%;
      }
      .btnExplain {
        display: inline-block;
        margin-top: 12px;
        color: color.$grayScale700;
      }
    }
  }
}

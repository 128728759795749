@use '@standby/dike_ui/design-token/color.module.scss' as color;

.beforeApplicationSubmission {
  .flexLineIntroduction {
    margin-top: 24px;
  }
}
.btnArea {
  position: fixed;
  left: calc(50% + 108px - 270px);
  bottom: 24px;
  button {
    padding: 16px 24px 12px 24px;
    background-color: color.$primary500;
    border-radius: 8px;
    box-shadow: 4px 4px 10px 0px rgba(24, 24, 24, 0.3);
    width: 540px;
    .btnText {
      color: color.$grayScale000;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
  ul {
    background-color: color.$primary900;
    margin-top: 12px;
    border-radius: 4px;
    color: color.$primary100;
    display: flex;
    justify-content: space-between;
    li {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      position: relative;
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 2px;
        height: 16px;
        background-color: color.$primary400;
      }
    }
  }
}

@use '@standby/dike_ui/design-token/color.module.scss' as color;

.faq {
  margin-top: 96px;
  > .title {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: color.$grayScale900;
  }
  > ul {
    margin-top: 48px;
    display: flex;
    flex-flow: column;
    gap: 12px;
    > li {
      border-radius: 12px;
      background: color.$grayScale100;
      padding: 20px 24px 20px 48px;
      position: relative;
      cursor: pointer;
      .q {
        display: inline-block;
        position: absolute;
        left: 24px;
        top: 20px;
        color: color.$primary400;
      }
      .arrow {
        position: absolute;
        top: 25px;
        right: 24px;
      }
      .title {
        color: color.$grayScale800;
      }
      .content {
        margin-top: 8px;
        display: block;
        color: color.$grayScale800;
      }
    }
  }
}

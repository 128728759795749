ul.fileList {
  margin-top: 4px;
  &.fileListNone {
    margin-top: 0;
    border: none;
  }
}

.preUploadFile {
  display: none;
}

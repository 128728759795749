@use '@standby/dike_ui/design-token/color.module.scss' as color;

.confirmText {
  color: color.$grayScale700;
  display: inline-block;
  padding-left: 12px;
}
.flexLine {
  padding-bottom: 150px;
  .flexLineIntroduction {
    margin-top: 24px;
  }
  .btnArea {
    position: fixed;
    left: calc(50% + 108px - 270px);
    bottom: 24px;
    button {
      padding: 24px;
      background-color: color.$primary500;
      border-radius: 8px;
      box-shadow: 4px 4px 10px 0px rgba(24, 24, 24, 0.3);
      width: 540px;
      .btnText {
        color: color.$grayScale000;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
    }
  }
}
